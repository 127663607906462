import { AktiaLogo } from '@taaleri/components/src/ui/AktiaLogo'
import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.div`
  background-color: ${Colors.chartLineGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 129px;
`
export default function Footer() {
  return (
    <FooterContainer style={{ marginTop: 20 }}>
      <AktiaLogo small={true} />
    </FooterContainer>
  )
}
