import { getRegisterSchema } from '@taaleri/components/src/screens/questions/registerSchema'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import UiButton from '@taaleri/components/src/ui/UiButton'
import UiLink from '@taaleri/components/src/ui/UiLink'
import ErrorMessage from '@taaleri/components/src/ui/form/ErrorMessage'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import { UiSwitch } from '@taaleri/components/src/ui/form/UiSwitch'
import { UiTextInputForm } from '@taaleri/components/src/ui/form/UiTextInput'
import { Paragraph } from '@taaleri/components/src/ui/text/Paragraph'
import { TextError, TextDefault } from '@taaleri/components/src/ui/text/UiText'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Analytics, { EventCategory } from '@taaleri/core/src/analytics/Analytics'
import { api } from '@taaleri/core/src/api/api'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { fiKey } from '@taaleri/core/src/i18n'
import { CustomerBasicInfo } from '@taaleri/core/src/models/Customer'
import { Registration } from '@taaleri/core/src/models/Identity'
import {
  CONFLICT,
  ServiceResponse,
  TEAPOT,
} from '@taaleri/core/src/models/ServiceResponse'
import { getIdentity } from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { registerFailed, registerSuccessful } from 'utils/DataLayer'

interface Props {
  registration?: Registration
  emailDisabled: boolean
  hideEmail?: boolean
  onSuccess?: (values: Registration) => void
  apiCall: (
    register: Registration,
    token?: string
  ) => Promise<ServiceResponse<any>>
  buttonTitle: string
  buttonTitleDone?: string
  hideLocalAuthentication?: boolean
  analyticsCategory: EventCategory
  analyticsAction: string
  toNextRoute: () => void
  whiteBackground?: boolean
  isOnboarding: boolean
  token: string
}

function RegisterForm(props: Props) {
  const {
    registration,
    onSuccess,
    buttonTitle,
    buttonTitleDone,
    apiCall,
    hideEmail,
    emailDisabled,
    analyticsCategory,
    analyticsAction,
    whiteBackground,
    isOnboarding,
    token,
  } = props

  const { t } = useTranslation()
  const [changeDone, setChangeDone] = useState<boolean>(false)
  const [loadingBasicInfo, setLoadingBasicInfo] = useState<boolean>(true)
  const [customerBasicInfo, setCustomerBasicInfo] =
    useState<CustomerBasicInfo>()
  const [error, setError] = useState<string | undefined>()
  const { isNonSmall } = useLayout()

  const initialValues: Registration = registration || {
    email: '',
    password: '',
    customerid: '',
  }

  const showIconDone = changeDone && buttonTitleDone !== undefined
  const grayBackground = whiteBackground !== true

  const termsUrl = () =>
    AppStore.currentLanguage === fiKey
      ? 'https://misc.aktia.fi/data-service/documents/investment/Asiakassopimukset/Sopimusehdot/Aktian_varainhoitopalveluiden_sopimusehdot.pdf'
      : 'https://misc.aktia.fi/data-service/documents/investment/Asiakassopimukset/Sopimusehdot/Aktia_kapitalforvaltningstjanster_avtalsvillkor.pdf'

  const privaceStatementUrl = () =>
    AppStore.currentLanguage === fiKey
      ? 'https://www.aktia.fi/docs/default-source/yleiset/tietosuojaseloste.pdf?Status=Master&sfvrsn=fa53f967_34'
      : 'https://www.aktia.fi/docs/default-source/yleiset/tietosuojaseloste_sv.pdf?Status=Master&sfvrsn=fa53f967_34'

  const titleInButton = changeDone
    ? buttonTitleDone || buttonTitle
    : buttonTitle

  const getBasicInfo = async () => {
    if (registration && registration.customerid) {
      const basicInfo = await api().customer.getCustomerBasicInfoWithToken(
        token,
        Number.parseInt(registration.customerid, 10)
      )
      setCustomerBasicInfo(basicInfo)
      setLoadingBasicInfo(false)
    }
  }

  useEffect(() => {
    if (loadingBasicInfo) {
      getBasicInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getRegisterSchema(!emailDisabled, t)}
        onSubmit={async (
          values: Registration,
          actions: FormikHelpers<Registration>
        ) => {
          try {
            const identity = await getIdentity()
            const token = identity ? identity.accessToken : undefined
            const apiResponse = await apiCall(values, token)
            Analytics.event(analyticsCategory, analyticsAction)
            actions.setSubmitting(false)
            if (apiResponse.success) {
              setChangeDone(true)
              if (onSuccess) {
                await onSuccess(values)
              }
              registerSuccessful()
              props.toNextRoute()
            } else {
              let errorMsg: string
              if (apiResponse.error === CONFLICT) {
                errorMsg = ErrorMessages.emailTaken
              } else if (apiResponse.error === TEAPOT) {
                errorMsg = ErrorMessages.registrationError
              } else {
                errorMsg = ErrorMessages.unexpectedError
              }

              if (apiResponse.error === CONFLICT) {
                actions.setFieldError('email', errorMsg)
              } else {
                setError(errorMsg)
              }
              registerFailed(errorMsg)
            }
          } catch (err) {
            actions.setSubmitting(false)
            setError(ErrorMessages.unexpectedError)
            registerFailed(ErrorMessages.unexpectedError)
          }
        }}
      >
        {({ isSubmitting, errors, submitCount, values, setFieldValue }) => (
          <UiForm>
            {loadingBasicInfo && !emailDisabled && <LoadingIndicator />}
            {!loadingBasicInfo && !emailDisabled && (
              <FlexRow style={styles.columnContainer}>
                <FlexRow style={styles.informationRow}>
                  <TextDefault>{t('form.firstname')}</TextDefault>
                  <TextDefault>
                    {customerBasicInfo?.customer.firstName}
                  </TextDefault>
                </FlexRow>
                <FlexRow style={styles.informationRow}>
                  <TextDefault>{t('form.lastname')}</TextDefault>
                  <TextDefault>
                    {customerBasicInfo?.customer.lastName}
                  </TextDefault>
                </FlexRow>
                <FlexRow style={styles.informationRow}>
                  <TextDefault>{t('form.personal-id')}</TextDefault>
                  <TextDefault>
                    {customerBasicInfo?.customer.identityNumber}
                  </TextDefault>
                </FlexRow>
                <FlexRow style={[styles.informationRow, styles.bottomMargin]}>
                  <TextDefault>{t('form.phone-number')}</TextDefault>
                  <TextDefault>{customerBasicInfo?.customer.phone}</TextDefault>
                </FlexRow>
              </FlexRow>
            )}
            {!hideEmail && (
              <UiTextInputForm
                name="email"
                label={`${t('email')}`}
                type="email"
                autoFocus={true}
                editable={props.emailDisabled !== true && !isSubmitting}
                grayBackground={grayBackground}
                onChangeText={() => setError(undefined)}
              />
            )}

            <Paragraph style={{ marginBottom: Spacings.S24 }}>
              {isOnboarding
                ? t('app.questions.onboardingregister.createsmartusername')
                : t(
                    'app.questions.onboardingregister.createsmartusername-short'
                  )}
            </Paragraph>
            <UiTextInputForm
              name="password"
              type="password"
              textContentType="password"
              label={
                hideEmail
                  ? `${t('question.credentials.new-password')}`
                  : `${t('password')}`
              }
              secureTextEntry={isSubmitting}
              useEyeOnOff={!isSubmitting}
              grayBackground={grayBackground}
              editable={!isSubmitting}
              onChangeText={() => {
                setChangeDone(false)
                setError(undefined)
              }}
            />
            {error && <ErrorMessage message={t(error)} />}
            {!emailDisabled && (
              <>
                <FlexRow style={styles.rowWithToggleButton}>
                  <FlexRow style={styles.toggleButtonTitle}>
                    <TextDefault>
                      {t('question.credentials.accept')}{' '}
                    </TextDefault>
                    <UiLink
                      text={`${t('question.credentials.terms-of-use')}`}
                      url={termsUrl()}
                      target="_blank"
                    />
                  </FlexRow>
                  <UiSwitch
                    value={values.termsAccepted}
                    onValueChange={(value: boolean) =>
                      setFieldValue('termsAccepted', value)
                    }
                  />
                </FlexRow>
                {errors.termsAccepted && submitCount > 0 && (
                  <TextError style={styles.validationError}>
                    {t(errors.termsAccepted)}
                  </TextError>
                )}
                <FlexRow style={[styles.rowWithToggleButton, styles.topMargin]}>
                  <FlexRow style={styles.toggleButtonTitle}>
                    <TextDefault>
                      {t('question.credentials.accept-privacy')}
                    </TextDefault>
                    <UiLink
                      text={`${t('question.credentials.privacy-terms-of-use')}`}
                      url={privaceStatementUrl()}
                      target="_blank"
                    />
                  </FlexRow>
                  <UiSwitch
                    value={values.privacyAccepted}
                    onValueChange={(value: boolean) =>
                      setFieldValue('privacyAccepted', value)
                    }
                  />
                </FlexRow>

                {errors.privacyAccepted && submitCount > 0 && (
                  <TextError style={styles.validationError}>
                    {t(errors.privacyAccepted)}
                  </TextError>
                )}
                <FlexRow style={[styles.rowWithToggleButton, styles.topMargin]}>
                  <FlexRow style={styles.toggleButtonTitle}>
                    <TextDefault>
                      {t('question.credentials.consent-info')}
                    </TextDefault>
                  </FlexRow>
                  <UiSwitch
                    value={values.infoCorrect}
                    onValueChange={(value: boolean) =>
                      setFieldValue('infoCorrect', value)
                    }
                  />
                </FlexRow>

                {errors.infoCorrect && submitCount > 0 && (
                  <TextError style={styles.validationError}>
                    {t(errors.infoCorrect)}
                  </TextError>
                )}
                <View style={{ marginBottom: Spacings.S32 }} />
              </>
            )}

            <UiButton
              title={titleInButton}
              loading={isSubmitting}
              disabled={isSubmitting || showIconDone}
              iconCheck={showIconDone}
              containerStyle={[isNonSmall && { alignSelf: 'flex-end' }]}
            />
          </UiForm>
        )}
      </Formik>
    </>
  )
}

const styles = StyleSheet.create({
  informationRow: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },
  bottomMargin: {
    marginBottom: 30,
  },
  columnContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 5,
    width: '100%',
  },
  rowWithToggleButton: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 5,
  },
  topMargin: {
    marginTop: 5,
  },
  toggleButtonTitle: {
    alignItems: 'baseline',
    flexWrap: 'wrap',
    paddingRight: Spacings.S8,
    flex: 1,
  },
  validationError: {
    marginLeft: Spacings.S16,
  },
})

export default RegisterForm
