import React from 'react'
import { Animated, StyleProp, StyleSheet } from 'react-native'

const FlexRow: React.FC<{ style?: StyleProp<any> }> = ({ children, style }) => (
  <Animated.View style={[styles.flexRow, style]}>{children}</Animated.View>
)

export default FlexRow

const styles = StyleSheet.create({
  flexRow: { flexDirection: 'row' },
})
