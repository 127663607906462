import {
  Address,
  Customer,
  CustomerAuthorization,
  CustomerResponse,
} from './Customer'

export interface Identity {
  readonly accessToken: string
  readonly refreshToken: string
  readonly expiresOn: number
  readonly tokenType: string
  readonly customerId: number
}

export interface IdentityResponse {
  isSuccess: boolean
  isConflict: boolean
  expiresOn: number
  token?: string
  refreshToken?: string
  customerId?: number
  processId: string
  customer: {
    customerId: string
    firstName: string
    lastName: string
    phoneNumber: string
    emailAddress: string
    address: Address
  }
  customerAuthorizations: CustomerAuthorization[]
}

export interface Registration {
  email: string
  password: string
  termsAccepted?: boolean
  customerid: string
  privacyAccepted?: boolean
  infoCorrect?: boolean
}

export enum PasswordRecoveryMode {
  NotFound = 'notfound',
  NewCustomer = 'new',
  ForgotPassword = 'forgotpassword',
}

export interface TwoFactor {
  isTwoFactor: boolean
  twoFactorId: string
}

export interface Login {
  identity: Identity
  customer: Customer
  twoFactor: TwoFactor
  isOnboardingInProgress: boolean
  lastUsedLanguage: string
}

export interface LoginResponse extends IdentityResponse {
  customer: CustomerResponse
  customerAuthorizations: CustomerAuthorization[]
  isOnboardingInProgress: boolean
  isTwoFactor?: boolean
  twoFactorId?: string
  lastUsedLanguage?: string
}

export type SmSVerificationStatus = 'Success' | 'WrongPin' | 'Expired'

export interface SmsVerification {
  success: boolean
  status: SmSVerificationStatus
  refreshToken?: string
}
