import React from 'react'
import { StyleSheet } from 'react-native'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { TextBold, TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Fonts, {
  FontSize,
  SizeLargeResponsive,
} from '@taaleri/core/src/constants/Fonts'
import { getChangeColor } from '@taaleri/components/src/ui/text/ColorFormattedNumber'
import Spacings from '@taaleri/core/src/constants/Spacings'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import {
  IconEuro,
  IconEuroSelected,
  IconTwr,
  IconTwrSelected,
  IconSecurityPriceSelected,
  IconSecurityPrice,
} from '@taaleri/components/src/ui/icons/ChartIcons'
import Colors from '@taaleri/core/src/constants/Colors'
import useLayout from '@taaleri/components/src/ui/useLayout'
import {
  formatCurrency,
  formatFundProfit,
} from '@taaleri/core/src/utils/format'
import {
  KeyFigureType,
  MARKET_VALUE,
  PERFORMANCE,
  VOLATILITY,
  SECURITY_PRICE,
} from '@taaleri/core/src/constants/KeyFigures'
import { BenchMarkMenu } from './BenchMarkMenu'
import MarketValueInfoButton from '@taaleri/components/src/ui/MarketValueInfoButton'

interface Props {
  marketValueChange: number
  twrChange: number
  securityPrice: string
  hideSecurityPrice?: boolean
  volatilityChange: number
  showBenchMarkMenu: boolean
  graphType: KeyFigureType
  setGraphType: (type: KeyFigureType) => void
  title: string
  hideVolatility?: boolean
}

function ReportChartHeader({
  marketValueChange,
  twrChange,
  volatilityChange,
  graphType,
  setGraphType,
  securityPrice,
  hideSecurityPrice,
  showBenchMarkMenu,
  title,
  hideVolatility,
}: Props) {
  const { isSmall } = useLayout()

  const isTwr = graphType === PERFORMANCE
  const isVolatility = graphType === VOLATILITY
  const isMarketValue = graphType === MARKET_VALUE
  const isSecurityPrice = graphType === SECURITY_PRICE
  return (
    <FlexRow
      style={{
        justifyContent: 'space-between',
        paddingHorizontal: Spacings.PADDING,
        height: 56,
        alignItems: 'center',
      }}
    >
      <FlexRow style={{ alignItems: 'center' }}>
        <TextBold
          style={{
            fontFamily: Fonts.avenirNextMedium,
            textTransform: 'uppercase',
            letterSpacing: 1,
            fontSize: FontSize.S10,
            paddingRight: 12,
          }}
        >
          {title}
        </TextBold>
        {
          <>
            {marketValueChange !== null && isMarketValue && (
              <TextDefault
                type="h2"
                style={styles.percentage}
                color={getChangeColor(marketValueChange)}
              >
                {formatCurrency(marketValueChange)}
              </TextDefault>
            )}
            {securityPrice !== undefined && isSecurityPrice && (
              <TextDefault type="h2" style={styles.percentage}>
                {securityPrice}
              </TextDefault>
            )}
            {twrChange !== null && isTwr && (
              <TextDefault
                type="h2"
                style={styles.percentage}
                color={getChangeColor(twrChange)}
              >
                {formatFundProfit(twrChange)}
              </TextDefault>
            )}
            {volatilityChange !== null &&
              !isNaN(volatilityChange) &&
              isVolatility && (
                <TextDefault type="h2" style={styles.percentage}>
                  {formatFundProfit(volatilityChange)}
                </TextDefault>
              )}
          </>
        }
        {isSecurityPrice || <MarketValueInfoButton style={styles.infoButton} />}
      </FlexRow>
      {isSmall || (
        <FlexRow>
          {showBenchMarkMenu && <BenchMarkMenu style={styles.benchMark} />}
          <UiTouchable onPress={() => setGraphType(PERFORMANCE)}>
            {isTwr ? <IconTwrSelected /> : <IconTwr />}
          </UiTouchable>
          <UiTouchable
            onPress={() => setGraphType(MARKET_VALUE)}
            style={styles.euroIcon}
          >
            {isMarketValue ? <IconEuroSelected /> : <IconEuro />}
          </UiTouchable>
          {hideVolatility || (
            <UiTouchable
              onPress={() => setGraphType(VOLATILITY)}
              style={styles.euroIcon}
            >
              {isVolatility ? <IconEuroSelected /> : <IconEuro />}
            </UiTouchable>
          )}
          {hideSecurityPrice || (
            <UiTouchable
              onPress={() => setGraphType(SECURITY_PRICE)}
              style={styles.euroIcon}
            >
              {isSecurityPrice ? (
                <IconSecurityPriceSelected />
              ) : (
                <IconSecurityPrice />
              )}
            </UiTouchable>
          )}
        </FlexRow>
      )}
    </FlexRow>
  )
}

export default ReportChartHeader

const styles = StyleSheet.create({
  marketValueChange: {
    fontSize: SizeLargeResponsive(),
    letterSpacing: -0.2,
    marginRight: Spacings.S16,
  },

  percentage: {
    fontSize: SizeLargeResponsive(),
    letterSpacing: -0.2,
  },
  euroIcon: {
    paddingLeft: 12,
    marginLeft: 12,
    borderLeftColor: Colors.border,
    borderLeftWidth: 1,
  },
  benchMark: {
    paddingRight: 12,
    marginRight: 12,
    borderRightColor: Colors.border,
    borderRightWidth: 1,
  },
  infoButton: {
    justifyContent: 'center',
    marginLeft: Spacings.S8,
  },
})
