import { YieldHistory } from './YieldHistory'

export interface AssetClassItem {
  name: string
  friendlyName: string
  securityName: string
  ticker: string
  marketValue: number
  purchaseValue: number
  marketValueChangePercent: number | undefined
  sharePercentage: number
  isImpact?: boolean
  ownFund?: boolean
  securityTypeId?: string
  quantity?: number
  assetCategoryId: string
  assetCategoryName: string
  assetAdditionalInfoId: string
  assetAdditionalInfoName: string
  accruedInterest?: number
  duration?: number
  yieldToMaturity?: number
  purchasePrice?: number
  marketPrice?: number
  marketPriceWithoutInterests?: number
  isSecurityDocumentAvailable?: boolean
  commitmentAmount?: number
  uncommitmentAmount?: number
  assetClassId?: string
  couponRate?: number
  maturityDate?: string
  nameFi: string
  nameSv: string
  nameEn: string
  isCapitalFund: boolean
}

export interface AssetClass {
  id: string
  name: string
  marketValue: number
  purchaseValue: number
  marketValueChangePercent: number | undefined
  marketValueChange: number
  percentageOfTotal: number
  items: AssetClassItem[]
}

export interface CurrencyValue {
  currency: string
  value: number
}

export interface FinancialInstrument {
  assetClassId: string
  name: string
  ticker: string
  friendlyName: string
  securityName: string
  isImpact: boolean
  ownFund: boolean
  securityTypeId?: string
  assetClassName: string
  assetCategoryId: string
  assetCategoryName: string
  assetAdditionalInfoId: string
  assetAdditionalInfoName: string
  couponRate?: number
  maturityDate?: string
  securityNameEnglish: string
  securityNameFinnish: string
  securityNameSwedish: string
  isCapitalFund: boolean
}

export interface Currency {
  baseCurrency: CurrencyValue
  securityCurrency: CurrencyValue
}

export interface Position {
  financialInstrument: FinancialInstrument
  marketValue: Currency
  purchaseValue: Currency
  marketValueChangePercent: Currency
  isImpact: boolean
  quantity?: number
  positionFiguresData: PositionFiguresData
  purchasePrice: Currency
  marketPrice: Currency
  marketPriceWithoutInterests: Currency
  isSecurityDocumentAvailable?: boolean
  commitmentAmount?: number
  uncommitmentAmount?: number
}

export interface SecurityDetail {
  purchaseLots: PurchaseLot[]
  yieldHistory: YieldHistory
}

export interface PurchaseLot {
  quantity: number
  purchaseDate: string
  purchasePrice?: number
  marketValue: number
  marketPrice?: number
  marketPriceWithoutInterests?: number
  purchaseValue: number
  securityTypeId?: string
  marketValueChangePercent: number | undefined
}

export interface PurchaseLotResponse extends Position {
  quantity: number
  purchaseDate: string
  purchasePrice: Currency
  marketPrice: Currency
}

export interface PortfolioResponse extends FilteredPositionsResponse {
  marketValueChange: MarketValueChange
}

export interface FilteredPositionsResponse {
  marketValue: CurrencyValue
  purchaseValue: CurrencyValue
  positions: Position[]
}

export interface PositionFiguresData {
  accruedInterest: number
  duration: number
  yieldToMaturity: number
}

export interface FundPropositionResponse {
  positions: PortfolioResponse
  actualRisk: number
  actualVolatility: number
  simulatedRisk: number
  simulatedVolatility: number
}

// Increment if interface changes
export const PortfolioVersion = '1'

export type PortfolioType = 'consultative' | 'discretionary' | 'other'
export interface Portfolio extends FilteredPositions {
  marketValueChange: MarketValueChange
}

export interface FilteredPositions {
  portfolioType?: PortfolioType
  customerId?: number
  version?: string
  marketValue: number
  marketValueChange: MarketValueChange
  purchaseValue: number
  assetClasses: AssetClass[]
}

export interface MarketValueChange {
  oneMonth: number
  yearToDate: number
  oneYear: number
  total: number
  unrealizedProfit?: number
  marketValueChange?: number
}
