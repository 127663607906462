import {
  ReportingPortfolioResponse,
  ReportingPortfolio,
} from '../../models/ReportingPortfolio'

export function toReportingPortfolioList(res: any) {
  return Object.keys(res).map((r) => toReportingPortfolio(res[r]))
}

export function toReportingPortfolio(
  source: ReportingPortfolioResponse
): ReportingPortfolio {
  return {
    id: source.id,
    name: source.name,
    type: source.type,
    contract: source.contract,
    nameSv: source.nameSv,
    isCapitalInvestedMaintained: source.isCapitalInvestedMaintained,
    isConverted: source.isConverted,
    isInsurance: source.isInsurance,
    isTvK: source.isTvK,
    isRestricted: source.isRestricted,
    hideTransactions: source.hideTransactions,
  }
}
