import { observer } from 'mobx-react'
import React from 'react'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import {
  identityCardDateFormatted,
  identityCardValid,
} from '../identitycard/identityCardUpdate'
import ActionBox from './ActionBox'
import { PropsOnPress } from '../../components/PropsChildren'
import { useTranslation } from 'react-i18next'

function IdentityCardUpdateBox(props: PropsOnPress) {
  const showBox = AppStore.showIdentityCardBox
  const { t } = useTranslation()

  if (!showBox) {
    return null
  }

  const formattedDate = identityCardDateFormatted(AppStore.customer)
  const hasExpired =
    AppStore.customer.idValidity &&
    !identityCardValid(AppStore.customer.idValidity)
  return (
    <ActionBox
      onPress={() => {
        Analytics.event('IdentityCard', 'Update box click')
        QuestionStore.questionMode = 'contract'
        props.onPress()
      }}
    >
      {hasExpired
        ? t('home.identity-card-update-expired', { date: formattedDate })
        : t('home.identity-card-update-going-to-expire', {
            date: formattedDate,
          })}
    </ActionBox>
  )
}

export default observer(IdentityCardUpdateBox)
