export type ReportingPortfolioType = 'Own' | 'Aggregate' | 'Insurance'

export interface ReportingPortfolio {
  id: string
  name: string
  type: ReportingPortfolioType
  contract: string
  nameSv: string
  isCapitalInvestedMaintained: boolean
  isConverted: boolean
  isInsurance: boolean
  isTvK: boolean
  isRestricted: boolean
  hideTransactions: boolean
}

export interface ReportingPortfolioResponse {
  id: string
  name: string
  type: ReportingPortfolioType
  contract: string
  nameSv: string
  isCapitalInvestedMaintained: boolean
  isConverted: boolean
  isInsurance: boolean
  isTvK: boolean
  isRestricted: boolean
  hideTransactions: boolean
}

export function ReportingPortfolioTypeToName(type: ReportingPortfolioType) {
  if (type === 'Insurance') {
    return 'portfolio.insurance'
  }
  if (type === 'Aggregate') {
    return 'portfolio.aggregate'
  }
  return 'portfolio.wealth-own'
}
