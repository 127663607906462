import { formatYLabel } from '@taaleri/components/src/screens/calculator/CalculatorGraph'
import { PdfLink } from '@taaleri/components/src/screens/portfolio/PdfLink'
import ReportChart from '@taaleri/components/src/screens/portfolio/ReportChart'
import ReportChartLegend from '@taaleri/components/src/screens/portfolio/ReportChartLegend'
import { toReportChartProps } from '@taaleri/components/src/screens/portfolio/toReportChartProps'
import { getSecurityPdfUrl } from '@taaleri/components/src/screens/reporttransaction/TransactionPdfUrl'
import { Divider } from '@taaleri/components/src/ui/Divider'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { IconInfo } from '@taaleri/components/src/ui/Icons'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import Box, { boxPadding } from '@taaleri/components/src/ui/box/Box'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import {
  KeyFigureType,
  MARKET_VALUE,
  VOLATILITY,
  SECURITY_PRICE,
  PERFORMANCE,
} from '@taaleri/core/src/constants/KeyFigures'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { SecurityPrice } from '@taaleri/core/src/models/SecurityPriceResponse'
import { TimeSerieItem } from '@taaleri/core/src/models/YieldHistory'
import { translateFilterItem } from '@taaleri/core/src/services/translationService'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportStore, {
  ReportTimeSeries,
} from '@taaleri/core/src/stores/ReportStore'
import {
  formatCurrency,
  formatFundProfit,
  formatNumber,
  formatNumberWithPrefix,
  roundToNearest,
} from '@taaleri/core/src/utils/format'
import {
  calculateMarketValueChange,
  calculateTwrChange,
  calculateVolatilityChange,
  getFinalMarketValue,
} from '@taaleri/core/src/utils/keyFigureHelper'
import { observer } from 'mobx-react'
import React, { useState, useEffect, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import ReportBondRow from './ReportBondRow'
import ReportChartHeader from './ReportChartHeader'

interface ReportChartContainerProps {
  benchMarkTimeSeries: TimeSerieItem[]
  securityPrice?: SecurityPrice
  portfolioTimeSeries: ReportTimeSeries
  reportTimeSeries: ReportTimeSeries
  graphType: KeyFigureType
  setGraphType: (type: KeyFigureType) => void
  subtitle?: string
  isDrillDown: boolean
  loading?: boolean
}

function formatPercentageChange(value: number, withPrefix?: boolean) {
  return withPrefix
    ? formatNumberWithPrefix(roundToNearest(value - 100, 0.01)) + '%'
    : formatNumber(roundToNearest(value - 100, 0.01)) + '%'
}

function ReportChartContainer({
  benchMarkTimeSeries,
  reportTimeSeries,
  securityPrice,
  isDrillDown,
  portfolioTimeSeries,
  children,
  loading,
  graphType,
  subtitle,
  setGraphType,
}: PropsWithChildren<ReportChartContainerProps>) {
  const {
    selectedPosition,
    isSelectedPositionPrivateEquity,
    selectedAssetClassItem,
  } = ReportStore
  const [showPortfolioChart, setShowPortfolioChart] = useState<boolean>(true)
  const hideSecurityPrice =
    !selectedPosition || !securityPrice?.timeSeries?.length

  const pdfUrl =
    selectedAssetClassItem?.isSecurityDocumentAvailable && ReportStore.token
      ? getSecurityPdfUrl(
          AppStore.customerId,
          selectedAssetClassItem.ticker,
          ReportStore.token
        )
      : undefined

  useEffect(() => {
    if (hideSecurityPrice && graphType === SECURITY_PRICE) {
      setGraphType(PERFORMANCE)
    }
  }, [hideSecurityPrice, graphType, setGraphType])
  const { t } = useTranslation()

  let timeSeries: TimeSerieItem[] = []
  let formatTooltipValue: (value: number) => string
  let marketValueChange = 0
  let twrChange = 0
  let volatilityChange = 0
  let currentSecurityPrice = 0
  let title = ''
  let valueFormatFunction: (value: number) => string
  let showBenchMarkMenu = false
  let portfolioData: any
  let chartTitle = ''
  let portfolioTitle = ''
  let benchmarkTitle = ''
  let formattedSecurityPrice = ''

  let fixedSubtitle: string | undefined = ''
  if (subtitle) {
    if (subtitle.split('-').length > 1) {
      fixedSubtitle = `${t(subtitle.split('-')[0].trim())} - ${subtitle
        .split('-')[1]
        .trim()}`
    } else {
      fixedSubtitle = `${t(subtitle)}`
    }
  } else {
    fixedSubtitle = undefined
  }

  switch (graphType) {
    case VOLATILITY:
      timeSeries = reportTimeSeries.volatilityTimeSeries ?? []
      formatTooltipValue = (value: number) => formatFundProfit(value - 100)
      title = t('graph.volatility')
      valueFormatFunction = (value: number) =>
        formatPercentageChange(value, false)
      portfolioData = toReportChartProps(
        timeSeries,
        [],
        portfolioTimeSeries.volatilityTimeSeries
      )
      volatilityChange = calculateVolatilityChange(portfolioData.data)
      chartTitle = `${fixedSubtitle || t('portfolio.text')} ${formatFundProfit(
        volatilityChange
      )}`
      portfolioTitle = `${t('portfolio.text')} ${formatFundProfit(
        calculateTwrChange(portfolioData.portfolioData)
      )}`
      break
    case SECURITY_PRICE:
      timeSeries = securityPrice?.timeSeries ?? []
      const currency = securityPrice?.currency ?? ''
      formatTooltipValue = isSelectedPositionPrivateEquity
        ? formatFundProfit
        : (value: number) => `${formatNumber(value)} ${currency}`
      title = t('graph.market-price')
      valueFormatFunction = formatNumber
      portfolioData = toReportChartProps(timeSeries, [], [], false)
      currentSecurityPrice = timeSeries[timeSeries.length - 1]?.value
      formattedSecurityPrice = formatTooltipValue(currentSecurityPrice)
      chartTitle = `${fixedSubtitle ?? ''} ${formattedSecurityPrice}`
      break
    case MARKET_VALUE:
      timeSeries = reportTimeSeries.marketValueTimeSeries ?? []
      formatTooltipValue = (value: number) => formatCurrency(Math.round(value))
      marketValueChange = calculateMarketValueChange(
        reportTimeSeries.marketValueTimeSeries
      )
      title = t('graph.market-price-change')
      valueFormatFunction = formatYLabel
      portfolioData = toReportChartProps(
        timeSeries,
        [],
        portfolioTimeSeries.marketValueTimeSeries
      )
      chartTitle = `${
        fixedSubtitle || t('portfolio.text')
      } ${formatTooltipValue(
        getFinalMarketValue(reportTimeSeries.marketValueTimeSeries)
      )}`
      portfolioTitle = `${t('portfolio.text')} ${formatTooltipValue(
        getFinalMarketValue(portfolioTimeSeries.marketValueTimeSeries)
      )}`
      break
    default:
      // PERFORMANCE as default type
      timeSeries = reportTimeSeries.twrTimeSeries ?? []
      formatTooltipValue = (value: number) =>
        formatFundProfit(value - 100, true)
      title = t('graph.profit')
      showBenchMarkMenu = true
      valueFormatFunction = (value: number) =>
        formatPercentageChange(value, true)
      portfolioData = toReportChartProps(
        timeSeries,
        benchMarkTimeSeries ?? [],
        portfolioTimeSeries.twrTimeSeries,
        true
      )
      twrChange = calculateTwrChange(portfolioData.data)
      chartTitle = `${fixedSubtitle || t('portfolio.text')} ${formatFundProfit(
        twrChange,
        true
      )}`
      portfolioTitle = `${t('portfolio.text')} ${formatFundProfit(
        calculateTwrChange(portfolioData.portfolioData),
        true
      )}`
      benchmarkTitle = ReportStore.selectedBenchMark
        ? `${t(ReportStore.selectedBenchMark)} ${formatFundProfit(
            calculateTwrChange(portfolioData.benchMark),
            true
          )}`
        : ''
  }

  // Show empty chart if less than 2 data points
  const showEmptyChart = timeSeries.length < 2

  return (
    <Box style={{ flex: 1, paddingBottom: Spacings.S8 }}>
      <FlexRow
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottomWidth: 1,
          borderBottomColor: Colors.border,
          paddingHorizontal: boxPadding,
        }}
      >
        <TextDefault
          type="h5"
          style={{ marginTop: Spacings.S16, marginBottom: 12 }}
        >
          {fixedSubtitle ?? t('portfolio.wealth-graph-title')}
        </TextDefault>
        {!loading && (
          <TextDefault type="t2" numberOfLines={1} style={{ minWidth: 50 }}>
            {typeof ReportStore.dateRangeTitle === 'string'
              ? ReportStore.dateRangeTitle
              : translateFilterItem(ReportStore.dateRangeTitle, t)}
          </TextDefault>
        )}
      </FlexRow>
      {selectedAssetClassItem?.isCapitalFund !== true && <ReportBondRow />}
      <View style={{ flex: 1 }}>
        {!loading && (
          <ReportChartHeader
            hideSecurityPrice={hideSecurityPrice}
            securityPrice={formattedSecurityPrice}
            showBenchMarkMenu={showBenchMarkMenu}
            marketValueChange={marketValueChange}
            twrChange={twrChange}
            volatilityChange={volatilityChange}
            graphType={graphType}
            setGraphType={setGraphType}
            title={title}
            hideVolatility={true}
          />
        )}
        <View style={{ zIndex: -1, flex: 1 }}>
          {loading && (
            <LoadingIndicator
              style={{
                paddingTop: Spacings.S16,
                position: 'absolute',
                top: 0,
                height: '100%',
                width: '100%',
                backgroundColor: Colors.white,
                zIndex: 1000,
              }}
            />
          )}
          <View
            style={{
              flex: 1,
            }}
          >
            <Divider />
            {selectedAssetClassItem?.isCapitalFund === true &&
              pdfUrl !== undefined && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <IconInfo
                    color={Colors.primary}
                    style={{
                      left: 25,
                      alignSelf: 'center',
                    }}
                    width={40}
                    height={40}
                  />
                  <TextDefault
                    style={{
                      marginLeft: 45,
                      marginTop: 9,
                      color: Colors.primary,
                    }}
                  >
                    {t('portfolio.capital-fund-information')}
                  </TextDefault>
                  <PdfLink
                    {...{ pdfUrl }}
                    style={{
                      marginLeft: Spacings.PADDING,
                      marginTop: 2,
                      marginRight: 10,
                    }}
                    color={Colors.primary}
                    fontSize={18}
                  />
                </View>
              )}
            {showEmptyChart ? (
              <View style={{ flex: 1 }} />
            ) : (
              <ReportChart
                {...portfolioData}
                valueFormatFunction={valueFormatFunction}
                formatTooltipValue={formatTooltipValue}
                color={ReportStore.chartColor}
                graphType={graphType}
                showPortfolioChart={isDrillDown && showPortfolioChart}
              />
            )}
          </View>
        </View>
        {!loading && (
          <ReportChartLegend
            style={{
              zIndex: -1,
              marginHorizontal: Spacings.PADDING,
              marginVertical: Spacings.S8,
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              paddingTop:
                selectedAssetClassItem?.isCapitalFund === true
                  ? Spacings.S32
                  : 0,
            }}
            showBenchmarkLegend={graphType === 'PERFORMANCE'}
            chartTitle={chartTitle}
            showPortfolioLegend={isDrillDown && graphType !== SECURITY_PRICE}
            setShowPortfolioChart={setShowPortfolioChart}
            showPortfolioChart={showPortfolioChart}
            benchmarkTitle={benchmarkTitle}
            portfolioTitle={portfolioTitle}
          />
        )}
        {children}
      </View>
    </Box>
  )
}

export default observer(ReportChartContainer)
