import AsyncStorage from '@react-native-async-storage/async-storage'
import { isWeb } from '@taaleri/components/src/constants/Platforms'
import {
  identityCardUpdateNeeded,
  identityCardValid,
} from '@taaleri/components/src/screens/identitycard/identityCardUpdate'
import {
  ImpactParameters,
  calculateCo2Compensation,
} from '@taaleri/components/src/screens/questions/impactCalculator'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import {
  action,
  computed,
  observable,
  runInAction,
  makeAutoObservable,
} from 'mobx'
import { create, persist } from 'mobx-persist'

import { Withdrawal } from './../models/Payment'
import QuestionStore from './QuestionStore'
import ReportStore from './ReportStore'
import UserStore from './UserStore'
import { api } from '../api/api'
import { TestCustomer } from '../api/customerApiMock'
import { isLocalMock } from '../config'
import {
  ASSET_CLASS_ID_CASH,
  ASSET_CLASS_ID_OTHER,
  ASSET_CLASS_ID_IMPACT,
  ASSET_CLASS_NAME_IMPACT,
} from '../constants/AssetClass'
import emptyWarning from '../constants/EmptyWarning'
import { Contract } from '../models/Contract'
import {
  AccountManager,
  Customer,
  CustomerAuthorization,
} from '../models/Customer'
import { Feed } from '../models/Feed'
import { Fund } from '../models/Fund'
import { Identity, Registration, TwoFactor } from '../models/Identity'
import { CameraPhoto } from '../models/IdentityCard'
import {
  ImpactProduct,
  ImpactOverPeriod,
  EmptyImpactOverPeriod,
} from '../models/ImpactProduct'
import { Allocation } from '../models/InvestmentStrategy'
import { Maintenance, MaintenanceWithWarning } from '../models/Maintenance'
import {
  pendingOrdersBuySum,
  pendingOrdersSellSum,
  pendingOrdersSellEverything,
  Order,
  incominOrdersBuySum,
} from '../models/Order'
import {
  AssetClass,
  Portfolio,
  PortfolioVersion,
  AssetClassItem,
  PortfolioType,
} from '../models/Portfolio'
import { ReportingPortfolio } from '../models/ReportingPortfolio'
import { SavingsPlan } from '../models/SavingsPlan'
import { TwrChange, YieldHistory } from '../models/YieldHistory'
import {
  InvestmentProposal,
  PortfolioDistributions,
  SdgWithCount,
} from '../models/investmentProposal'
import { customers } from '../services/customers'
import {
  getCustomerFunds,
  customerFundRecommendations,
  getOpenOrders,
} from '../services/funds'
import { impact } from '../services/impact'
import { getOrders } from '../services/orders'
import {
  getCustomerPortfolio,
  getYieldHistory,
  getCustomerPortfolioList,
  getReportingPortfolioList,
} from '../services/reports'
import { getSavingsPlan } from '../services/savingsPlan'
import {
  getVisitCount,
  removeLocalAuthentication,
  removeLocalSignedIn,
  removeLoggedIn,
  removeIdentity,
  setCredentials,
  setIdentity,
  setLocalAuthentication,
  setLoggedIn,
  setVisitCount,
} from '../services/storage'
import {
  isDateMoreThanElevenMonthsAgo,
  isDateMoreThanYearAgo,
  today,
} from '../utils/date'
import { formatDateDash, formatDateFull, inKilos } from '../utils/format'
import { getSdgs } from '../utils/getSdgs'
import logger from '../utils/logger'

type InvestmentMode = 'default' | 'digi' | 'fund' | 'withdraw' | 'withdrawfund'
type PaymentType = 'default' | 'onetime' | 'recurring'

class AppStoreInternal {
  constructor() {
    makeAutoObservable(this)
  }

  @observable
  customerMaybe?: Customer = isLocalMock() ? TestCustomer : undefined

  @computed get customer(): Customer {
    if (!this.customerMaybe) {
      throw new Error('AppStore Customer missing')
    }
    return this.customerMaybe
  }

  @observable
  activeCustomerMaybe?: Customer = isLocalMock() ? TestCustomer : undefined

  @persist
  @observable
  currentLanguage = 'fi'

  @computed get activeCustomer(): Customer {
    if (!this.activeCustomerMaybe) {
      throw new Error('Active customer missing')
    }
    return this.activeCustomerMaybe
  }

  @computed get recurringOrders(): Order[] {
    return this.orders.filter((order) => order.isRecurring)
  }

  @persist
  @observable
  smsConfirmationId?: string

  @persist
  @observable
  investmentMode: InvestmentMode = 'default'

  @persist
  @observable
  paymentType: PaymentType = 'default'

  @persist
  @observable
  adminToken?: string

  @computed get isAdmin(): boolean {
    return Boolean(this.adminToken)
  }

  @computed get showSummary(): boolean {
    return this.isAdmin && false // true/false to control is this feature on or off
  }

  @persist
  @observable
  paymentDay: number = Math.min(new Date().getDate(), 28)

  @observable
  investmentProposalSaved?: InvestmentProposal

  @observable
  authorizations?: Customer[] = undefined

  @observable
  totalEmission: ImpactOverPeriod = EmptyImpactOverPeriod

  @observable
  impactStrategy?: PortfolioDistributions = undefined

  @observable
  totalEnergy: ImpactOverPeriod = EmptyImpactOverPeriod

  @observable
  pastImpactProducts: ImpactProduct[] = []

  @observable
  recommendedImpactProducts: ImpactProduct[] = []

  @observable
  customerImpactProducts: ImpactProduct[] = []

  @observable
  allFunds: Fund[] = []

  @observable
  customerFunds: Fund[] = []

  @observable
  recommendedFunds: Fund[] = []

  @observable
  portfolioInternal?: Portfolio

  @observable
  savingsPlan?: SavingsPlan

  @observable
  reportingPortfolioList?: ReportingPortfolio[] = []

  @observable
  maintenance?: MaintenanceWithWarning

  @observable
  portfolioList: Portfolio[] = []

  @observable
  yieldHistory?: YieldHistory

  @observable
  portfolioProgress?: TwrChange

  @observable
  photoId?: CameraPhoto | null

  @observable
  contract?: Contract

  @observable
  loading = false

  @observable
  loadingOrders = false

  @observable
  loadingSavingsPlan = false

  @observable
  loadingFund = false

  @observable
  contentLoaded = false

  @persist
  @observable
  openConsultative = false

  @observable
  error = false

  @observable
  hideReportTransactionBox = true

  @observable
  localAuthenticationEnabled = false

  @persist
  @observable
  pushNotificationsEnabled = false

  @persist
  @observable
  identityCardUpdated = false

  @observable
  showPicker = false

  @observable
  showFeedbackModal = false

  @observable
  showSavingsPlanDoneModal = false

  @action setShowSavingsPlanDoneModal(value: boolean) {
    this.showSavingsPlanDoneModal = value
  }

  @observable
  showContractkModal = false

  @persist
  @observable
  pushNotificationPermissionAsked = false

  @persist
  @observable
  showImpactModal = true

  @observable
  isTwoFactor = false

  @persist
  @observable
  twoFactorEnabled = false

  @observable
  showContractForcekModal = false

  @observable
  showContractBox = false

  @observable
  showIdentityCardBox = false

  @observable
  updateFeedCache = false

  @observable
  showOnboardingSuccessModal = false

  @observable
  showDefaultCustomerSelection = false

  @observable
  forceContractUpdate = false

  @observable
  isOnboardingSignedIn = false

  @persist
  @observable
  lastRefreshDate?: string

  @persist
  @observable
  lastFeedDate: string | undefined = undefined

  runInBackground: Date | undefined = undefined

  @observable
  feeds: Feed[] | undefined

  @observable
  hasNewMessages = false

  @observable
  showPaymentReceivedModal = false

  @persist
  @observable
  defaultCustomerId?: number

  @persist
  @observable
  simpleFeedbackDismissed = false

  @persist
  @observable
  newInvestment = 0

  @persist
  @observable
  newInvestmentConfirmed = 0

  @persist
  @observable
  incomingInvestmentConfirmed = 0

  @observable
  newWithdrawal = 0

  @persist
  @observable
  newWithdrawalConfirmed = 0

  @observable
  withdrawAll = false

  @persist
  @observable
  impactShare = 0

  @persist
  @observable
  riskLevel = 0

  @observable
  expectedReturn = 0

  @observable
  otaUpdate = false
  @observable
  storeUpdate = false
  @observable
  storeUpdateForce = false

  @persist
  @observable
  fundId: string | undefined

  @persist
  @observable
  recurringOrderId: string | undefined

  @observable
  fund: Fund | undefined

  @persist
  @observable
  contractNextScreen: string | undefined

  @observable
  orders: Order[] = []

  @observable
  openOrders: Order[] = []

  @observable
  withdrawal?: Withdrawal

  @observable
  routeAfterLogin?: string

  @observable
  securities: Allocation[] = []

  @observable
  loadingImpact = false

  @persist
  @observable
  warningNotificationDismissed = false

  @persist('object')
  @observable
  warningNotificationData: Maintenance = emptyWarning

  @observable
  currentRoute: string | undefined

  hasUnreadMessages = (): boolean => {
    if (!this.feeds) {
      return false
    }
    if (this.feeds.length === 0) {
      return false
    }
    return this.lastFeedDate !== this.feeds[0].date
  }

  @action
  setCustomer = (customer: Customer) => {
    this.customerMaybe = customer
    this.activeCustomerMaybe = customer
  }

  @action
  setCustomerId = (customerId: number) => {
    this.activeCustomerMaybe = { ...this.customer, customerId }
  }

  customerHasAuthorizations = (): boolean => {
    return (
      this.customerMaybe !== undefined &&
      this.customerMaybe.customerAuthorizations.length > 1
    )
  }

  getAccountManager = (): AccountManager | null => {
    const customer = this.activeCustomer
    if (!customer) {
      return null
    }
    const accountManager = customer.accountManager
    if (!accountManager) {
      return null
    }
    return accountManager
  }

  @action
  IsReportOrdersForbidden = (): boolean => {
    const customer = this.activeCustomer
    return customer?.rules?.reportOrdersForbidden ?? false
  }

  @action
  IsIncomeReportHidden = (): boolean => {
    const customer = this.activeCustomer
    return customer?.rules?.hideIncomeReport ?? false
  }

  @action setPortfolio = (portfolio?: Portfolio) => {
    /*
    For pure digi customers, create artificial impact asset class
    simply by changing asset class id and name
    */
    if (this.isPureDigiCustomer && portfolio) {
      const otherClass = portfolio.assetClasses.find(
        (a) => a.id === ASSET_CLASS_ID_OTHER
      )
      if (otherClass) {
        otherClass.id = ASSET_CLASS_ID_IMPACT
        otherClass.name = ASSET_CLASS_NAME_IMPACT
      }
    }
    this.portfolioInternal = portfolio
  }

  @computed get portfolio(): Portfolio | undefined {
    return this.portfolioInternal
  }

  @action
  setPhotoId = (photoId: CameraPhoto | null) => {
    this.photoId = photoId
  }

  @action
  setContract = async (contract: Contract) => {
    this.contract = contract
  }

  @action
  setLocalAuthenticationEnabled = async (enabled: boolean) => {
    this.localAuthenticationEnabled = enabled

    if (enabled) {
      await setLocalAuthentication()
    } else {
      await removeLocalAuthentication()
    }
  }

  @action
  setLanguage = (language: string) => {
    this.currentLanguage = language
  }

  setRunInBackground() {
    this.runInBackground = new Date()
  }

  logoutNeeded() {
    if (this.runInBackground && this.portfolio) {
      const maximumTimeInBackground = 1000 * 60 * 15
      return (
        new Date().getTime() - this.runInBackground.getTime() >
        maximumTimeInBackground
      )
    }
    return false
  }

  getAssetClass = (name: string): AssetClass | undefined => {
    if (this.portfolio) {
      return this.portfolio.assetClasses.find(
        (assetClass) => assetClass.name === name
      )
    }
    throw new Error('no portfolio')
  }

  getAssetClassById = (
    id: string,
    portfolio?: Portfolio
  ): AssetClass | undefined => {
    if (portfolio) {
      return portfolio.assetClasses.find((assetClass) => assetClass.id === id)
    }
    throw new Error('no portfolio')
  }

  getAssetClassItemByTicker = (
    ticker: string,
    assetClassId: string
  ): AssetClassItem | undefined => {
    const assetClass = this.getAssetClassById(assetClassId, this.portfolio)
    return (
      assetClass &&
      assetClass.items.find(
        (assetClassItem) => assetClassItem.ticker === ticker
      )
    )
  }

  allAssetClassItems = (portfolio?: Portfolio): AssetClassItem[] => {
    if (!portfolio) {
      return []
    }

    return flatten(
      portfolio.assetClasses.map((a) => {
        return a.items
      })
    )
  }

  getAssetClassItemByTickerFirst = (
    ticker: string,
    portfolioType: PortfolioType
  ): AssetClassItem | undefined => {
    const portfolio = this.portfolioList.find(
      (p) => p.portfolioType === portfolioType
    )
    return this.allAssetClassItems(portfolio).find(
      (assetClassItem) => assetClassItem.ticker === ticker
    )
  }

  refreshPortfolioNeeded = () => {
    return !this.portfolio && !this.isOnboardingSignedIn && !AppStore.loading
  }

  @computed get customerHasImpact(): boolean {
    return this.customerImpactProducts.length !== 0
  }

  @computed get showImpact(): boolean {
    const isPureConsWithoutImpact =
      this.isConsultative &&
      this.customerCo2Actualized === 0 &&
      !this.isDigiDiscretionary
    return !(isPureConsWithoutImpact || this.showEmptyHomeScreen)
  }

  @computed get hasImpactStrategy(): boolean {
    return this.impactShare !== 0
  }

  @computed get isPureDigiCustomer(): boolean {
    return this.isDigiDiscretionary && this.reportingPortfolioList?.length === 1
  }

  @computed get customerCo2Estimate(): number {
    /* 
    For digi customers, we cannot use estimate from Impact Api
    Thus we calculate estimate based on current investments
    */
    if (this.isDigiDiscretionary) {
      const parameters: ImpactParameters = {
        impactShare: this.impactShare,
        expectedReturn: this.expectedReturn / 100,
        initialInvestment: this.portfolioMarketValue,
        actualizedCo2Kilos: this.customerCo2Actualized,
        monthlyInvestment: 0,
        investmentTime: 20,
        isPrivateEquity: false,
      }
      return calculateCo2Compensation(parameters)
    } else {
      return Math.max(
        inKilos(this.totalEmission.estimation),
        this.customerCo2Actualized
      )
    }
  }

  @computed get customerCo2Actualized(): number {
    return inKilos(this.totalEmission.total)
  }

  @computed get customerCo2future(): number {
    return Math.max(this.customerCo2Estimate - this.customerCo2Actualized, 0)
  }

  @computed get showImpactToggle(): boolean {
    return this.isDigiDiscretionary && !this.hasImpactStrategy
  }

  @computed get isPrivateCustomer(): boolean {
    return !this.isDigiOrConsultative
  }

  @computed get showContactCustomerServiceBox(): boolean {
    return (
      this.customer.isDigiConversionAllowed ||
      this.customer.isDigiConverted ||
      this.customer.isConsultative ||
      (this.customer.hasDigiBanker && !this.customer.isDigiCustomer)
    )
  }

  @action
  signOnboardingUserIn = async (
    registration: Registration,
    identity: Identity
  ) => {
    UserStore.loggedIn = true
    this.runInBackground = undefined
    this.isOnboardingSignedIn = true
    await setIdentity(identity)
    await setLoggedIn()
    if (!isWeb) {
      await setCredentials(registration)
    }
  }

  @computed get isEvervestCustomerWithoutContract() {
    return (
      this.isDigiDiscretionary &&
      (!this.customer.basicContractSigned || !this.customer.digiContractSigned)
    )
  }

  @computed get forceEvervestContract() {
    return this.isEvervestCustomerWithoutContract && this.showContractBox
  }

  @computed get isNewCustomerWithoutContract() {
    return (
      this.isDigiCustomer &&
      (!this.customer.basicContractSigned ||
        !this.customer.digiContractSigned) &&
      !this.customer.isCustomer &&
      this.isPerson
    )
  }

  // Has digi portfolio and digi strategy OR segment is digi
  @computed get isDigiCustomer(): boolean {
    // fallback to digi customer if not logged in
    return false
  }

  // Has digi portfolio and digi strategy
  @computed get isDigiDiscretionary(): boolean {
    // fallback value false
    return false
  }

  @computed get isDigiImpactCustomer(): boolean {
    return false
  }

  @computed get isDigiEtfCustomer(): boolean {
    return false
  }

  @computed get isCustomerBlocked(): boolean {
    return !this.activeCustomerMaybe || this.activeCustomer.isBlocked
  }

  @computed get customerAge(): number | undefined | null {
    return this.activeCustomerMaybe && this.activeCustomerMaybe.age
  }

  @computed get isDigiOrConsultative(): boolean {
    return false
  }

  @computed get isDigiAndConsultative(): boolean {
    return false
  }

  @computed get canRemoveAccount(): boolean {
    // fallback value false
    return Boolean(
      this.isDigiDiscretionary ||
        this.isDigiImpactCustomer ||
        this.isConsultative
    )
  }

  @action
  signInCustomer = async (customer: Customer) => {
    if (
      customer.customerAuthorizations &&
      customer.customerAuthorizations.length > 1
    ) {
      const defaultCustomerAuthorizationMaybe =
        customer.customerAuthorizations.find(
          (a) => a.id === String(this.defaultCustomerId)
        )
      if (
        defaultCustomerAuthorizationMaybe &&
        this.defaultCustomerId &&
        this.defaultCustomerId !== customer.customerId
      ) {
        const defaultCustomerMaybe = await customers.getCustomer(
          this.defaultCustomerId
        )
        if (defaultCustomerMaybe) {
          this.activeCustomerMaybe = defaultCustomerMaybe
        }
      }
    }
    this.postCustomerView()
    this.loadCustomerImpact()
    this.allFunds = []
    this.adminToken = undefined
    this.loadInvestmentProposalSaved()
    this.loadReportingPortfolios()
    await this.loadPortfolio()
    const visitCount = await getVisitCount()
    if (visitCount > 9) {
      this.showFeedbackModal = true
    }
    await setVisitCount(visitCount + 1)
    await this.contractUpdateCheck()
    await this.identityCardUpdateCheck()
    this.identityCardUpdated =
      !isEmpty(customer.idValidity) &&
      customer.idValidity !== undefined &&
      identityCardValid(customer.idValidity)
    await this.fetchOrders()
    QuestionStore.basicInfoLoaded = false
    this.contractNextScreen = undefined
    this.smsConfirmationId = undefined
    this.showDefaultCustomerSelection =
      this.customerHasAuthorizations() && !this.defaultCustomerId
  }

  @action fetchOrders = async () => {
    this.loadingOrders = true
    const customer = this.activeCustomer
    this.openOrders = []
    this.orders = []
    this.incomingInvestmentConfirmed = 0
    this.newInvestmentConfirmed = 0
    this.newWithdrawalConfirmed = 0
    this.withdrawAll = false
    if (customer && (customer.isDiscretionary || this.isConsultative)) {
      const orders = await getOrders(this.customerId)
      const openOrders = await getOpenOrders(this.customerId)
      this.openOrders = openOrders
      this.orders = orders
      this.incomingInvestmentConfirmed = incominOrdersBuySum(orders)
      this.newInvestmentConfirmed = pendingOrdersBuySum(orders)
      this.newWithdrawalConfirmed = pendingOrdersSellSum(orders)
      this.withdrawAll = pendingOrdersSellEverything(orders)
    }
    this.loadingOrders = false
  }

  @action fetchSavingsPlan = async () => {
    this.loadingSavingsPlan = true
    this.savingsPlan = undefined
    if (this.isDigiDiscretionary) {
      const { success, response } = await getSavingsPlan(this.customerId)
      if (success && response) {
        this.savingsPlan = response
      }
    }
    this.loadingSavingsPlan = false
  }

  @action
  signUserIn = async (
    identity: Identity,
    customer: Customer,
    registration?: Registration
  ) => {
    this.runInBackground = undefined
    this.isOnboardingSignedIn = false
    this.customerChanged(customer.customerId)
    await this.setCustomer(customer)
    await setIdentity(identity)
    await setLoggedIn()
    await this.signInCustomer(customer)
    if (!isWeb && registration) {
      await setCredentials(registration)
    }
    UserStore.loggedIn = true
  }

  @action
  setWarningNotificationDismissed = (isDismissed: boolean) => {
    this.warningNotificationDismissed = isDismissed
  }

  @action
  setWarningNotificationDataToEmpty = () => {
    this.warningNotificationData = emptyWarning
  }

  checkWarningNotification = async () => {
    const { warning } = await api().versionApi.maintenance()
    this.warningNotificationData = warning
  }

  @action
  setTwoFactor = async (twoFactor: TwoFactor, registration: Registration) => {
    this.isTwoFactor = twoFactor.isTwoFactor
    this.twoFactorEnabled = twoFactor.isTwoFactor
    this.smsConfirmationId = twoFactor.twoFactorId
    if (!isWeb) {
      await setCredentials(registration)
    }
  }

  @computed get basicInfoExpired(): boolean {
    if (this.customer.basicContractSigned) {
      return isDateMoreThanYearAgo(new Date(this.customer.basicContractSigned))
    }
    return true
  }

  @action
  contractUpdateCheck = async () => {
    if (this.customer.basicContractSigned) {
      const startRemindingOnUpdate = isDateMoreThanElevenMonthsAgo(
        new Date(this.customer.basicContractSigned)
      )

      if (startRemindingOnUpdate) {
        this.showContractBox = true
      }

      /*
        const showContractUpdatePopup = isDateMoreThanYearAgo(
          new Date(this.customer.basicContractSigned)
        )
        if (showContractUpdatePopup) {
          if (
            isDateMoreThanThirteenMonthsAgo(
              new Date(this.customer.basicContractSigned)
            )
          ) {
            this.forceContractUpdate = true
            this.showContractForcekModal = true
          } else {
            this.showContractkModal = true
          }
        }
        */
    } else {
      this.showContractBox = false
    }
  }

  @action
  identityCardUpdateCheck = async () => {
    if (this.isBaseCustomerAllowedToUpdateContract) {
      if (this.customer.idValidity) {
        this.showIdentityCardBox = identityCardUpdateNeeded(
          formatDateDash(new Date()),
          this.customer.idValidity
        )
      }
    }
  }

  @action
  customerChanged = (customerId: number) => {
    if (
      this.activeCustomerMaybe &&
      this.customerMaybe &&
      customerId !== this.activeCustomer.customerId
    ) {
      this.setPortfolio(undefined)
      this.portfolioList = []
      this.yieldHistory = undefined
      this.customerImpactProducts = []
    }
  }

  @action
  signOut = async () => {
    UserStore.loggedIn = false
    this.authorizations = undefined
    this.runInBackground = undefined
    this.setPortfolio(undefined)
    this.portfolioList = []
    this.customerImpactProducts = []
    this.recommendedImpactProducts = []
    this.isOnboardingSignedIn = false
    this.adminToken = undefined
    this.customerFunds = []
    this.recommendedFunds = []
    this.pastImpactProducts = []
    this.totalEnergy = EmptyImpactOverPeriod
    this.totalEmission = EmptyImpactOverPeriod
    this.twoFactorEnabled = false
    this.feeds = []
    this.securities = []
    this.orders = []
    this.lastRefreshDate = undefined
    this.openOrders = []
    this.investmentMode = 'default'
    await removeLoggedIn()
    await removeLocalSignedIn()
  }

  @action
  signOutWeb = async () => {
    await this.signOut()
    await removeIdentity()
    ReportStore.reset()
    UserStore.loggedIn = false
  }

  @action
  loadCustomerFunds = async () => {
    try {
      this.error = false
      const customerFunds = await getCustomerFunds()
      runInAction(() => {
        this.customerFunds = customerFunds
      })
    } catch (error) {
      logger.error(error, `Failed to fetch customer funds: ${error}`)
      this.error = true
    }
  }

  @action
  loadCustomerRecommendedFunds = async () => {
    try {
      this.error = false
      const recommendedFunds = await customerFundRecommendations()
      runInAction(() => {
        this.recommendedFunds = recommendedFunds
      })
    } catch (error) {
      logger.error(error, `Failed to fetch recommended funds: ${error}`)
      this.error = true
    }
  }

  @action
  loadInvestmentProposalSaved = async () => {
    if (this.activeCustomer && this.activeCustomer.customerId) {
      if (this.activeCustomer.isDiscretionary) {
        try {
          this.loading = true
          this.error = false
          const investmentProposalSaved =
            await customers.getInvestmentProposalSaved(
              this.activeCustomer.customerId
            )
          runInAction(() => {
            this.investmentProposalSaved = investmentProposalSaved
            this.impactShare = investmentProposalSaved.impactShare || 0
            this.expectedReturn = investmentProposalSaved.expectedReturn || 0
            this.riskLevel = investmentProposalSaved.riskLevel || 0
            this.loading = false
          })
        } catch (error) {
          logger.error(
            error,
            `Failed to fetch investment proposal saved: ${error}`
          )
          this.loading = false
          this.error = true
        }
      }
    } else {
      logger.error(
        new Error('Customer id empty'),
        `Customer id empty when fetching investment proposal saved`
      )
    }
  }

  @action
  loadCustomerImpact = async () => {
    if (this.activeCustomer && this.activeCustomer.customerId) {
      try {
        this.loadingImpact = true
        this.error = false
        const impactTask = impact.getCustomerImpact(
          this.activeCustomer.customerId
        )
        const sdgTask = impact.getCustomerSdgProducts(
          this.activeCustomer.customerId
        )
        const [impactResult, sdgResult] = await Promise.all([
          impactTask,
          sdgTask,
        ])
        const {
          totalEmission,
          totalEnergy,
          recommendedImpactProducts,
          pastImpactProducts,
          ownImpactProducts,
        } = impactResult

        const { success, response } = sdgResult

        runInAction(() => {
          this.customerImpactProducts = ownImpactProducts
          this.recommendedImpactProducts = recommendedImpactProducts
          this.pastImpactProducts = pastImpactProducts
          this.totalEnergy = totalEnergy
          this.totalEmission = totalEmission

          if (success && response) {
            this.securities = orderBy(
              response.allocations,
              'allocationShare',
              'desc'
            )
          }
          this.loadingImpact = false
        })
      } catch (error) {
        logger.error(error, `Failed to fetch total impact and sdgs: ${error}`)
        this.loadingImpact = false
        this.error = true
      }
    } else {
      logger.error(
        new Error('Customer id empty'),
        `Customer id empty when fetching total impact and sdgs`
      )
    }
  }

  @computed
  get portfolioMarketValue(): number {
    return this.portfolio ? this.portfolio.marketValue : 0
  }

  @computed
  get discreationaryPortfolioMarketValue(): number {
    const digiPortfolio = this.portfolioList.find(
      (p) => p.portfolioType === 'discretionary'
    )
    return digiPortfolio ? digiPortfolio?.marketValue : 0
  }

  @action
  loadPortfolio = async () => {
    if (this.activeCustomer && this.activeCustomer.customerId) {
      try {
        this.loading = true
        this.error = false
        const isConsultative = this.activeCustomer.isConsultative
        const isDiscretionary = this.activeCustomer.isDiscretionary
        const [portfolio, portfolioList, yieldHistory] = await Promise.all([
          getCustomerPortfolio(this.activeCustomer.customerId),
          isConsultative && isDiscretionary
            ? getCustomerPortfolioList(this.activeCustomer.customerId)
            : [],
          getYieldHistory(this.activeCustomer.customerId),
        ])
        portfolio.customerId = this.activeCustomer.customerId
        portfolio.version = PortfolioVersion

        runInAction(() => {
          this.setPortfolio(portfolio)
          this.portfolioList =
            portfolioList.length > 0
              ? portfolioList
              : [
                  {
                    ...portfolio,
                    portfolioType: isDiscretionary
                      ? 'discretionary'
                      : isConsultative
                      ? 'consultative'
                      : 'other',
                  },
                ]
          this.yieldHistory = yieldHistory
          this.portfolioProgress = yieldHistory.twrChange
          this.lastRefreshDate = formatDateDash(today())
          this.loading = false
        })
      } catch (error) {
        logger.error(error, `Failed to fetch portfolio: ${error}`)
        this.loading = false
        this.error = true
      }
    } else {
      logger.error(
        new Error('Customer id empty'),
        `Customer id empty when fetching portfolio`
      )
    }
  }

  @action
  postCustomerView = async () => {
    if (this.activeCustomer && this.activeCustomer.customerId) {
      try {
        await customers.postCustomerView(this.activeCustomer.customerId)
      } catch (error) {
        logger.error(error, `Failed to post customer view: ${error}`)
      }
    } else {
      logger.error(
        new Error('Customer id empty'),
        `Customer id empty when posting customer view`
      )
    }
  }

  @action
  loadReportingPortfolios = async () => {
    if (this.activeCustomer && this.activeCustomer.customerId) {
      try {
        this.error = false
        const reportingPortfolioList = await getReportingPortfolioList(
          this.activeCustomer.customerId
        )

        runInAction(() => {
          this.reportingPortfolioList = reportingPortfolioList
        })
      } catch (error) {
        logger.error(error, `Failed to fetch reportingPortfolios: ${error}`)
        this.error = true
      }
    } else {
      logger.error(
        new Error('Customer id empty'),
        `Customer id empty when fetching reportingPortfolio`
      )
    }
  }

  @computed get portfolioDate() {
    try {
      if (this.yieldHistory) {
        return formatDateFull(this.yieldHistory.endDate)
      }
      return formatDateFull(new Date().toLocaleDateString())
    } catch {
      return ''
    }
  }

  @computed get customerId(): number {
    return this.activeCustomerMaybe ? this.activeCustomer.customerId || 0 : 0
  }

  @computed get baseCustomerId(): number {
    return this.customerMaybe ? this.customer.customerId || 0 : 0
  }

  @computed get isConsultative(): boolean {
    return false
  }

  @computed get isConsultativeOrOpening(): boolean {
    return this.isConsultative || this.openConsultative
  }

  @computed get isConsultativeWithoutContract(): boolean {
    return this.forceConsultativeConversion || this.openConsultative
  }

  @computed get forceConsultativeConversion(): boolean {
    return (
      this.activeCustomer &&
      !this.activeCustomer.isConsultative &&
      this.activeCustomer.isDigiConversionAllowed &&
      !this.activeCustomer.isDigiConverted
    )
  }

  @computed get customerFirstName(): string | undefined {
    return this.customer?.firstName?.split(' ')[0]
  }

  @computed get isBaseCustomerActive(): boolean {
    return this.activeCustomerMaybe && this.customerMaybe
      ? this.customer.customerId === this.activeCustomer.customerId
      : false
  }

  @computed get isBaseCustomerAllowedToUpdateContract(): boolean {
    return this.activeCustomer
      ? !this.activeCustomer.incompetent &&
          (this.isBaseCustomerActive ||
            (!this.isBaseCustomerActive &&
              this.activeCustomer.isRepresentative))
      : false
  }

  @computed get isPerson(): boolean {
    return this.activeCustomer.accountType === 'Person'
  }

  // Show empty home screen only for pure digi customers, with only cash and without any investment history
  @computed get showEmptyHomeScreen(): boolean {
    return false
  }

  @computed get hasOnlyCash(): boolean {
    if (this.portfolio) {
      return !this.portfolio.assetClasses.some(
        (asset) => asset.id !== ASSET_CLASS_ID_CASH && asset.marketValue !== 0
      )
    }
    return true
  }

  @computed get cashValue(): number {
    if (this.portfolio) {
      return (
        this.portfolio.assetClasses.find(
          (asset) => asset.id === ASSET_CLASS_ID_CASH
        )?.marketValue || 0
      )
    }
    return 0
  }

  @computed get hasPortfolio(): boolean {
    return this.portfolio !== undefined && this.portfolio.marketValue > 0
  }

  @computed get hasPreviousHistory(): boolean {
    return Boolean(
      this.yieldHistory &&
        this.yieldHistory.timeSerieItems.some((x) => x.value !== 100)
    )
  }

  @computed get showPortfolio(): boolean {
    return this.portfolioMarketValue !== 0 || this.hasPreviousHistory
  }

  @computed
  get showFirstInvestment(): boolean {
    return (
      AppStore.isDigiDiscretionary &&
      !this.hasPortfolio &&
      this.newInvestmentConfirmed === 0 &&
      AppStore.isBaseCustomerActive
    )
  }

  @computed
  get showContractUpdate(): boolean {
    return this.isBaseCustomerAllowedToUpdateContract && this.isPerson
  }

  @computed
  get showOpenConsultative(): boolean {
    return (
      this.isBaseCustomerActive &&
      this.isDigiDiscretionary &&
      !this.isConsultative
    )
  }

  @computed get showPortfolioActions(): boolean {
    return false
  }

  @computed get getCurrentStrategy(): PortfolioDistributions | undefined {
    const riskLevel = this.riskLevel
    if (riskLevel) {
      return (
        this.investmentProposalSaved &&
        this.investmentProposalSaved.portfolioDistributions.find(
          (e) => e.riskLevel === riskLevel
        )
      )
    }
  }

  @computed get showWithdrawAction(): boolean {
    return false
  }

  @computed get showEuroProfit(): boolean {
    return (
      this.reportingPortfolioList?.every(
        (portfolio) => portfolio.isCapitalInvestedMaintained
      ) === true
    )
  }

  @action
  getPortfolioInformation = (id: string) => {
    return this.reportingPortfolioList?.find((p) => p.id === id)
  }

  @computed get portfoliosContainsRestricted(): boolean {
    return (
      this.reportingPortfolioList?.some(
        (portfolio) => portfolio.isRestricted
      ) === true
    )
  }

  @action hideTransactions(id: string): boolean {
    return this.getPortfolioInformation(id)?.hideTransactions ?? false
  }

  @action
  checkMultipleModals = () => {
    const NEXT_MODAL_TIMEOUT = 1000
    if (this.showOnboardingSuccessModal) {
      this.showOnboardingSuccessModal = false
      setTimeout(
        () => (this.showOnboardingSuccessModal = true),
        NEXT_MODAL_TIMEOUT
      )
    }
    if (this.showContractkModal) {
      this.showContractkModal = false
      setTimeout(() => (this.showContractkModal = true), NEXT_MODAL_TIMEOUT)
    }
    if (this.showContractForcekModal) {
      this.showContractForcekModal = false
      setTimeout(
        () => (this.showContractForcekModal = true),
        NEXT_MODAL_TIMEOUT
      )
    }
    /*if (!this.pushNotificationPermissionAsked) {
      this.pushNotificationPermissionAsked = true
      setTimeout(
        () => (this.pushNotificationPermissionAsked = false),
        NEXT_MODAL_TIMEOUT
      )
    }*/
    if (this.showDefaultCustomerSelection) {
      this.showDefaultCustomerSelection = false
      setTimeout(
        () => (this.showDefaultCustomerSelection = true),
        NEXT_MODAL_TIMEOUT
      )
    }
  }

  @action
  updateIsBlocked = async () => {
    const customer = await customers.getCustomer(this.customer.customerId)
    if (customer && this.activeCustomerMaybe) {
      this.activeCustomerMaybe.isBlocked = customer.isBlocked
    }
  }

  @action
  changeCustomer = async (customerAuthorization: CustomerAuthorization) => {
    AppStore.loading = true
    const customer = await customers.getCustomer(
      Number(customerAuthorization.id)
    )
    if (!customer) {
      return
    }
    const activeCustomerId = AppStore.activeCustomer.customerId
      ? AppStore.activeCustomer.customerId
      : undefined
    const customerId =
      customer && customer.customerId ? customer.customerId : undefined

    if (activeCustomerId && activeCustomerId !== customerId) {
      this.feeds = undefined
      this.activeCustomerMaybe = customer
      this.loadReportingPortfolios()
      this.loadCustomerImpact()
      this.postCustomerView()
      await this.loadInvestmentProposalSaved()
      await this.loadPortfolio()
      await this.fetchOrders()
      if (customer.customerId) {
        this.defaultCustomerId = customerId
      }
    } else {
      this.defaultCustomerId = customerId
    }
    AppStore.loading = false
  }

  @computed get sdgShare(): number {
    return this.sdgSecurities.reduce(
      (acc, curr) => acc + curr.allocationShare,
      0
    )
  }

  @computed get impactMarketValue(): number {
    return this.sdgSecurities.reduce(
      (acc, curr) => acc + (curr.marketValue || 0),
      0
    )
  }

  @computed get sdgSecurities(): Allocation[] {
    return this.securities.filter(
      (a) => a.sustainableDevelopmentGoals.length !== 0
    )
  }

  @computed get sdgs(): SdgWithCount[] {
    return getSdgs(this.securities)
  }

  @computed get sdgCount(): number {
    return this.sdgs.length
  }

  @action
  reset() {
    this.defaultCustomerId = undefined
    this.simpleFeedbackDismissed = false
    this.pushNotificationPermissionAsked = false
    this.newInvestment = 0
    this.newWithdrawal = 0
    this.newInvestmentConfirmed = 0
    this.newWithdrawalConfirmed = 0
    this.identityCardUpdated = false
    this.showImpactModal = true
  }
}

const hydrate = create({ storage: AsyncStorage, jsonify: true })
const AppStore = new AppStoreInternal()
export default AppStore

const result = hydrate('appStore', AppStore)
export async function rehydrateAppStore() {
  await result.rehydrate()
}
